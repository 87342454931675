import React from 'react';

import StoreLayout from '../../components/StoreLayout/StoreLayout';
import SEO from '../../components/SEO/SEO';

const ThankYouPage = () => (
  <StoreLayout page="shop">
    <SEO
      title="Thank You"
      description="Everything about Wibi Soerjadi, his history, life path and his amazing sheet music is available right here."
      keywords={[`wibi`, `soerjadi`, `wibi soerjadi`, `about`, `masterclass`, `summercourse`, `academy`, `sheet`, `music`, `sheet music`, `shop`]}
    />

    <main className="main">
      <div className="wrapper u-clearfix">
        <h1 className="main-title">Thank you for your purchase!</h1>
        <div className="success-purchase">
          <p>You'll receive an email with your items. Please also check your spam folder, as sometimes it might end up there.</p>
        </div>
      </div>
    </main>

  </StoreLayout>
);

export default ThankYouPage;
